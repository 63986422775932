<template>
  <div>
    <b-form-row>
      <b-col v-if="data.isFormat">
        <b-form-group :class="{ 'mb-0': data.selectedView == 'table-view' }">
          <b-form-radio-group
            :disabled="multilingualToggle"
            v-model="data.selectedView"
            @change="onChangeSelectedView"
            name="radio-formatResults"
            aria-describedby="Format results options"
          >
            <b-form-radio v-for="(option, fIndex) in formatWidgetResultsOptions" :value="option.value" :key="fIndex"
              >{{ option.text }}<sup class="pl-2" :id="option.value"><BIconInfoCircle scale="1.4" /></sup>
              <b-popover :target="option.value" placement="auto" triggers="hover focus" custom-class="format-results-popover">
                <img :src="require(`../../../../src/assets/img/${option.value}-help.png`)" :alt="option.text" class="img-fluid" />
              </b-popover>
            </b-form-radio>
          </b-form-radio-group>
          <b-form-row class="pt-3" v-if="data.selectedView == 'table-view'">
            <b-col sm="10" md="6" lg="5" xl="4">
              <b-form-group>
                <div class="floating-input-field">
                  <b-form-input
                    id="table-key-width"
                    :class="{
                      'is-invalid': (!Number.isInteger(data.keyWidth) && formSubmitted) || data.keyWidth > 100,
                    }"
                    type="number"
                    placeholder=" "
                    v-numberOnly
                    v-model.number="data.keyWidth"
                    @input="updateTableWidth('key')"
                    required
                  ></b-form-input>
                  <label for="table-key-width">Key width(%)</label>
                  <b-form-invalid-feedback class="d-block" v-if="!Number.isInteger(data.keyWidth) && formSubmitted"
                    >Key width required.</b-form-invalid-feedback
                  >
                  <b-form-invalid-feedback class="d-block" v-if="data.keyWidth > 100">Total width should be exactly 100%.</b-form-invalid-feedback>
                </div>
              </b-form-group>
            </b-col>
            <b-col sm="10" md="6" lg="5" xl="4">
              <b-form-group>
                <div class="floating-input-field">
                  <b-form-input
                    id="table-value-width"
                    :class="{
                      'is-invalid': (!Number.isInteger(data.valueWidth) && formSubmitted) || data.valueWidth > 100,
                    }"
                    type="number"
                    placeholder=" "
                    v-numberOnly
                    v-model.number="data.valueWidth"
                    @input="updateTableWidth('value')"
                    required
                  ></b-form-input>
                  <label for="table-value-width">Value width(%)</label>
                  <b-form-invalid-feedback class="d-block" v-if="!Number.isInteger(data.valueWidth) && formSubmitted"
                    >Value width required.</b-form-invalid-feedback
                  >
                  <b-form-invalid-feedback class="d-block" v-if="data.valueWidth > 100">Total width should be exactly 100%.</b-form-invalid-feedback>
                </div>
              </b-form-group>
            </b-col>
          </b-form-row>
        </b-form-group>
      </b-col>
    </b-form-row>
    <b-form-row v-if="data.isFormat">
      <b-col sm="10" md="6" lg="5" xl="4">
        <b-form-group>
          <div class="floating-select-field">
            <b-form-select
              id="dashboard-items"
              class="form-control"
              :options="widgetDashboardCountList"
              v-model.number="data.dashboardItemCnt"
              @input="data.dashboardItemCnt = $event"
              :class="{
                'is-value-exist': data.dashboardItemCnt != null,
                'is-invalid': !data.dashboardItemCnt && formSubmitted,
              }"
              :disabled="multilingualToggle"
            >
            </b-form-select>
            <label for="dashboard-items"
              >Dashboard items
              <InheritText v-if="multilingualToggle" />
            </label>
            <b-form-invalid-feedback class="d-block" v-if="!data.dashboardItemCnt && formSubmitted">Dashboard items required.</b-form-invalid-feedback>
          </div>
        </b-form-group>
      </b-col>
      <b-col sm="12" md="6" lg="4" class="pt-md-2 mt-md-1"> Recommended: 3</b-col>
    </b-form-row>
    <b-form-row class="mb-3" v-if="data.isFormat">
      <b-col>
        <b-button
          type="button"
          id="add-chart-btn"
          v-activeBlur
          variant="primary"
          aria-controls="chart-settings"
          :aria-expanded="showChartSettingsSideBar"
          :disabled="multilingualToggle"
          @click="addEditChart(null, -1)"
          >Add chart</b-button
        >
      </b-col>
    </b-form-row>
    <b-form-row v-if="chartConfigurations.length && data.isFormat" id="added-charts-list">
      <b-col>
        <div class="b-table-sticky-header custom-scroll">
          <table class="table">
            <thead>
              <tr>
                <th colspan="2">Chart title</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(chart, cIndex) in chartConfigurations" :key="cIndex">
                <td>{{ chart.chartTitle }}</td>
                <td class="text-right pr-0 py-2">
                  <b-dropdown boundary="window" class="action-dropdown" toggle-class="btn-h-44" no-caret variant="outline-secondary" right no-flip>
                    <template #button-content>
                      Actions
                      <BIconChevronDown class="pt-1" variant="dark" scale="0.65" />
                    </template>
                    <b-dropdown-item href="javascript:void(0)" @click="addEditChart(chart, cIndex)">Edit</b-dropdown-item>
                    <b-dropdown-item href="javascript:void(0)" @click="deleteChart(cIndex)">Delete</b-dropdown-item>
                  </b-dropdown>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </b-col>
    </b-form-row>
    <b-form-row class="mb-3" v-if="data.isFormat">
      <b-col>
        <b-button
          type="button"
          id="add-table-btn"
          v-activeBlur
          variant="primary"
          aria-controls="table-settings"
          :aria-expanded="showTableSettingsSideBar"
          :disabled="multilingualToggle"
          @click="addEditTable(null, -1)"
          >Add table</b-button
        >
      </b-col>
    </b-form-row>
    <b-form-row v-if="widgetTblConfigurations.length && data.isFormat">
      <b-col>
        <div class="b-table-sticky-header custom-scroll">
          <table class="table">
            <thead>
              <tr>
                <th colspan="2">Table title</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(table, tIndex) in widgetTblConfigurations" :key="tIndex">
                <td>{{ table.tableTitle }}</td>
                <td class="text-right pr-0 py-2">
                  <b-dropdown boundary="window" class="action-dropdown" toggle-class="btn-h-44" no-caret variant="outline-secondary" right no-flip>
                    <template #button-content>
                      Actions
                      <BIconChevronDown class="pt-1" variant="dark" scale="0.65" />
                    </template>
                    <b-dropdown-item href="javascript:void(0)" @click="addEditTable(table, tIndex)">Edit</b-dropdown-item>
                    <b-dropdown-item href="javascript:void(0)" @click="deleteTable(tIndex)">Delete</b-dropdown-item>
                  </b-dropdown>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </b-col>
    </b-form-row>
    <ChartSettings
      :showChartSettingsSideBar="showChartSettingsSideBar"
      :chartConfigurations="chartConfigurations"
      :editChartData="editChartData"
      @addUpdateChart="addUpdateChartConfigurations($event)"
      @closeSideBar="showChartSettingsSideBar = false"
    />
    <TableSettings
      :showTableSettingsSideBar="showTableSettingsSideBar"
      :widgetTblConfigurations="widgetTblConfigurations"
      :editTableData="editTableData"
      @addUpdateTable="addUpdatewidgetTblConfigurations($event)"
      @closeSideBar="showTableSettingsSideBar = false"
    />
    <ConfirmationModal
      :showModal="showDeleteChartConfirmModal"
      :title="`CONFIRM`"
      message="CHART_REMOVE"
      @onConfirm="confirmDeleteChart"
      @closeConfirmModal="showDeleteChartConfirmModal = false"
    />
    <ConfirmationModal
      :showModal="showDeleteTableConfirmModal"
      :title="`CONFIRM`"
      message="TABLE_REMOVE"
      @onConfirm="confirmDeleteTable"
      @closeConfirmModal="showDeleteTableConfirmModal = false"
    />
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { useValidateFields } from '@/composables/useValidateFields'
import { WidgetDashboardCountList, DISPLAY_MESSAGES } from '../../../utilities/constants'
import { BIconInfoCircle, BIconChevronDown } from 'bootstrap-vue'

export default {
  name: 'LocationWidget',
  props: ['widgetDetails', 'data', 'formSubmitted', 'chartConfigurations', 'widgetTblConfigurations', 'isEditPage', 'defaultCulture'],
  setup() {
    const { validateUrl } = useValidateFields()

    return { validateUrl }
  },
  components: {
    BIconInfoCircle,
    BIconChevronDown,
    ChartSettings: () => import('../../charts/ChartSettings.vue'),
    TableSettings: () => import('../../tables/TableSettings.vue'),
    ConfirmationModal: () => import('../../common/ConfirmationModal.vue'),
    InheritText: () => import('../../common/InheritText.vue'),
  },
  data() {
    return {
      DISPLAY_MESSAGES: DISPLAY_MESSAGES,
      widgetDashboardCountList: WidgetDashboardCountList,
      formatWidgetResultsOptions: [
        { text: 'Form view', value: 'form-view' },
        { text: 'Row view', value: 'row-view' },
        { text: 'Table view', value: 'table-view' },
      ],
      showChartSettingsSideBar: false,
      showDeleteChartConfirmModal: false,
      editChartIndex: -1,
      editChartData: null,
      showTableSettingsSideBar: false,
      showDeleteTableConfirmModal: false,
      editTableIndex: -1,
      editTableData: null,
    }
  },
  computed: {
    multilingualToggle() {
      return this.isMultilingualSupportEnabled && this.isDefaultCulture
    },
    isDefaultCulture() {
      return this.widgetDetails.culture !== this.defaultCulture
    },
    ...mapState({
      isMultilingualSupportEnabled: (state) => state.common.isMultilingualSupportEnabled,
    }),
  },
  methods: {
    onChangeSelectedView() {
      this.data.keyWidth = null
      this.data.valueWidth = null
    },
    updateTableWidth(column) {
      if (column === 'key') {
        this.data.valueWidth = 100 - this.data.keyWidth
      }
      if (column === 'value') {
        this.data.keyWidth = 100 - this.data.valueWidth
      }
    },
    showChartSettings() {
      this.showChartSettingsSideBar = true
    },
    addUpdateChartConfigurations(chartData) {
      if (this.editChartIndex > -1) {
        this.chartConfigurations[this.editChartIndex] = chartData
      } else {
        this.chartConfigurations.push(chartData)
      }
      this.showChartSettingsSideBar = false
    },
    addEditChart(chart, index) {
      this.editChartData = chart
      this.editChartIndex = index
      this.showChartSettingsSideBar = true
    },
    deleteChart(index) {
      this.editChartIndex = index
      this.showDeleteChartConfirmModal = true
    },
    confirmDeleteChart() {
      this.chartConfigurations.splice(this.editChartIndex, 1)
      this.showDeleteChartConfirmModal = false
    },
    showTableSettings() {
      this.showTableSettingsSideBar = true
    },
    addUpdatewidgetTblConfigurations(tableData) {
      if (this.editTableIndex > -1) {
        this.widgetTblConfigurations[this.editTableIndex] = tableData
      } else {
        this.widgetTblConfigurations.push(tableData)
      }
      this.showTableSettingsSideBar = false
    },
    addEditTable(table, index) {
      this.editTableData = table
      this.editTableIndex = index
      this.showTableSettingsSideBar = true
    },
    deleteTable(index) {
      this.editTableIndex = index
      this.showDeleteTableConfirmModal = true
    },
    confirmDeleteTable() {
      this.widgetTblConfigurations.splice(this.editTableIndex, 1)
      this.showDeleteTableConfirmModal = false
    },
  },
}
</script>
